import React, { useState } from "react";
import { connect } from "react-redux";
import {
    chatAssetPaymentStripeStart,
    chatAssetPaymentPaypalStart,
    chatAssetPaymentWalletStart,
    chatAssetPaymentCryptoStart,
} from "../../store/actions/ChatAssetAction";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import {
  Form,Button,Image,Modal,Tab,Nav,Row,Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../Environment";
import TokenModal from "./TokenModal";

const AssetPaymentModal = (props) => {
  const [paymentType, setPaymentType] = useState('');

  const [showPayPal, payPal] = useState(false);
  const [cryptoPaymentMode, setCryptoPaymentMode] = useState(0);

  const [amount, setAmount] = useState(0);
  const [hashKey, setHashKey] = useState("");
  const [hashElement, setHashElement] = useState(false);
  const [tokenModal, setTokenModal] = useState(false);
  const [coinType, setCoinType] = useState("");
  const [coinAddress, setCoinAddress] = useState("");
  const [coinValue, setCoinValue] = useState(0);
  const [coinQR, setCoinQR] = useState("");
  const [coins, setCoins] = useState(configuration.get("configData.coins"));
  
  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  const choosePaymentOption = (event) => {
    setPaymentType(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    chooseCryptoOption(cryptoPaymentMode);
    if (paymentType === "CARD")
      props.dispatch(
        chatAssetPaymentStripeStart({
          chat_message_id: props.paymentData.chat_message_id,
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        chatAssetPaymentWalletStart({
          chat_message_id: props.paymentData.chat_message_id,
        })
      );
    if (paymentType === "coin")
      props.dispatch(
        chatAssetPaymentCryptoStart({
          chat_message_id: props.paymentData.chat_message_id,
          amount: amount,
          hash_key: hashKey,
          coin_type: coinType,
          coin_value: coinValue,
        })
      );

    if (paymentType === "PAYPAL") showPayPal(true);
      
    props.closePaymentModal();
  };

  const paypalOnSuccess = (payment) => {
    console.log(payment);
    setTimeout(() => {
      props.dispatch(
        chatAssetPaymentPaypalStart({
          payment_id: payment.paymentID,
          chat_message_id: props.paymentData.chat_message_id,
        })
      );
    }, 1000);
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  const chooseCryptoOption = (event) => {
    const inputData = {
      price: props.paymentData.amount_formatted,
      symbol: coins[event].symbol,
    };
    const response = api.postMethod("coin/conversion", inputData);
    response
      .then((result) => {
        if (result.data.success) {
          setCoinType(coins[event].symbol);
          setCoinAddress(coins[event].address);
          setCoinQR(coins[event].qr_code);
          setPaymentType("coin");
          setAmount(props.paymentData.amount_formatted);
          setCoinValue(result.data.data.crypto_value);
          setTokenModal(true);
          setHashElement(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeTokenModal = () => {
    setTokenModal(false);
  };

  return (
    <>
      <Modal
        className="modal-dialog-center sent-tip-modal"
        size="md"
        centered
        show={props.chatPayment}
        onHide={props.closePaymentModal}
      >
        {props.chatPayment === true ? (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t("chat_message")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={paymentType}
              >
                <div className="new-subscriber-payment-details-sec">
                  <div className="subscriber-info-note">
                    <h4>Pay the amount to get asstet access</h4>
                    <p>
                      Please make the payment for {props.name} assets access
                    </p>
                  </div>
                  <div className="subscriber-plan-info">
                    <div className="subscriber-plan-details">
                      <Image
                        className="subscriber-plan-details-icon"
                        src={props.userPicture}
                      />
                      <div className="subscriber-type-plan">
                        <h4 className="text-uppercase">Pay</h4>
                        {/* <h5>CHANGE PLAN</h5> */}
                      </div>
                    </div>
                    <div className="subscriber-amount-info">
                      <span>{props.paymentData.amount}</span>
                    </div>
                  </div>
                </div>
                <Row>
                 
                 
                  <Col sm={12}>
                    <div className="subscriber-radio-title">
                      <h4>Choose Payment Method</h4>
                    </div>
                    <div className="subscriber-radio-btn-sec">
                      {configuration.get("configData.coins").length > 0
                        ? configuration
                            .get("configData.coins")
                            .map((value, index) => (
                              <div>
                                {" "}
                                <input
                                  className="checkbox-tools"
                                  type="radio"
                                  name="payment_mode"
                                  id={"coin-" + index}
                                  value={index}
                                  onChange={(event) =>
                                    setCryptoPaymentMode(index)
                                  }
                                />
                                <label
                                  className="for-checkbox-tools"
                                  for={"coin-" + index}
                                >
                                  <Image
                                    className="subscriber-radio-btn-img"
                                    src={value.logo}
                                  />
                                  <span>{value.name}</span>
                                </label>
                              </div>
                            ))
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
              {/* <div className="header-userinfo mb-0">
                <div className="popup-username-row">
                  <span className="pop-username popuser-realname">
                    <div className="pop-username">Chat Message Payment</div>
                  </span>
                </div>
              </div>

              <div className="floating-form">
                <div>
                  <div className="pop-user-username">
                    {t("amount")} - {props.paymentData.amount}
                  </div>
                </div>
                <Form className="mt-4">
                  {["radio"].map((type) => (
                    <div key={`custom-inline-${type}`} className="mb-3">
                      {configuration.get("configData.is_only_wallet_payment") == 0 ? 
                        <>
                        {configuration.get("configData.is_stripe_enabled") ==
                        1 && configuration.get("configData.stripe_publishable_key") !== "" && configuration.get("configData.stripe_secret_key") !== "" ? (
                          <Form.Check
                            custom
                            inline
                            label="Card"
                            type={type}
                            // id={`custom-inline-${type}-2`}
                            id="card"
                            value="CARD"
                            name="payment_type"
                            defaultChecked={paymentType == "CARD" ? true : false}
                            onChange={(event) => {
                              choosePaymentOption(event.currentTarget.value);
                            }}
                          />
                        ) : (
                          null
                        )}
                        {configuration.get("configData.is_paypal_enabled") ==
                        1 && configuration.get("configData.PAYPAL_ID") !== "" ? (
                          <Form.Check
                            custom
                            inline
                            label="Paypal"
                            type={type}
                            id="paypal"
                            value="PAYPAL"
                            name="payment_type"
                            defaultChecked={paymentType == "PAYPAL" ? true : false}
                            onChange={(event) => {
                              choosePaymentOption(event.currentTarget.value);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {configuration.get(
                          "configData.is_wallet_payment_enabled"
                        ) == 1 ? (
                          <Form.Check
                            custom
                            inline
                            label="Wallet"
                            type={type}
                            id="wallet"
                            value="WALLET"
                            name="payment_type"
                            onChange={(event) => {
                              choosePaymentOption(event.currentTarget.value);
                            }}
                          />
                        ) : null}
                        </>
                      : 
                        <>
                        {configuration.get(
                          "configData.is_wallet_payment_enabled"
                        ) == 1 ? (
                          <Form.Check
                            custom
                            inline
                            label="Wallet"
                            type={type}
                            id="wallet"
                            value="WALLET"
                            name="payment_type"
                            defaultChecked={paymentType == "WALLET" ? true : false}
                            onChange={(event) => {
                              choosePaymentOption(event.currentTarget.value);
                            }}
                          />
                        ) : null}
                        </>
                      }
                    </div>
                  ))}
                </Form>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              {paymentType === "PAYPAL" &&
              props.paymentData.amount_formatted != 0 ? (
                <PaypalExpressBtn
                  env={env}
                  client={client}
                  currency={currency}
                  total={props.paymentData.amount_formatted}
                  onError={paypalOnError}
                  onSuccess={paypalOnSuccess}
                  onCancel={paypalOnCancel}
                />
              ) : null}

              {/* <Button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={props.closePaymentModal}
              >
                {t("cancel")}
              </Button> */}
              {paymentType !== "PAYPAL" ? (
                <Button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleSubmit}
                  disabled={props.payStripe.buttonDisable}
                >
                  {props.payStripe.loadingButtonContent !== null
                    ? props.payStripe.loadingButtonContent
                    : t("pay_now")}
                </Button>
              ) : (
                ""
              )}
            </Modal.Footer>
          </Form>
        ) : null}
      </Modal>
      <TokenModal
        tokenModal={tokenModal}
        closeTokenModal={closeTokenModal}
        coinType={coinType}
        coinAddress={coinAddress}
        coinQR={coinQR}
        amount={amount}
        coinValue={coinValue}
        setHashKey={setHashKey}
        addressType="post"
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  payStripe: state.chatAsset.chatAssetPayStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(AssetPaymentModal));

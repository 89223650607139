import React, { useState } from "react";
import {
  Button,
  Container,
  Modal,
  Row,
  Col,
  Image,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { CopyToClipboard } from "react-copy-to-clipboard";
import api from "../../Environment";
import "./TokenModal.css";
import HashKeyInfo from "./HashKeyInfo";

const TokenModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center"
        size="lg"
        centered
        show={props.tokenModal}
        onHide={props.closeTokenModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.coinType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <p className="send-value">
                  Send <span>{props.coinValue}</span> to this address
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <div className="form-wallet mt-5">
                  <CopyToClipboard text={props.coinAddress}>
                    <InputGroup className="">
                      <FormControl
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={props.coinAddress}
                      />
                      <Button variant="outline-secondary" id="button-addon2">
                        Copy to Clipboard
                      </Button>
                    </InputGroup>
                  </CopyToClipboard>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="qr-div">
                  <Image
                    src={api.serviceUrl() + "/" + props.coinQR}
                    className="qr-code"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <div className="floating-form">
                  <div className="floating-label mb-4">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder="Hash Key"
                      onChange={(event) => {
                        props.setHashKey(event.currentTarget.value);
                      }}
                    />
                    <span className="highlight"></span>
                    <label className="default-label hash-key-tooltip">
                      {t("hash_key")}
                      <HashKeyInfo />
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            {props.addressType == "tips" ? (
              <Row>
                <Col md={{ span: 8, offset: 2 }}>
                  <div className="floating-form">
                    <div className="floating-label mb-4">
                      <input
                        className="floating-input"
                        type="text"
                        placeholder="Message (optional) "
                        onChange={(event) => {
                          props.setMessage(event.currentTarget.value);
                        }}
                      />
                      <span className="highlight"></span>
                      <label className="default-label">
                        {t("message")} ({t("optional")})
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <div className="floating-form">
                  <div className="floating-label mb-4 text-center">
                    <Button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={props.closeTokenModal}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-success ml-2"
                      data-dismiss="modal"
                      onClick={props.handleSubmit}
                    >
                      {props.addressType == "tips"
                        ? t("send_tip")
                        : t("pay_now")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(translate(TokenModal));

import React, { useState } from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const HashKeyInfo = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      We can add here copy & paste your hash key transaction after the pay
    </Tooltip>
  );
  return (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Image
          src={window.location.origin + "/assets/images/icons/info.svg"}
          className="svg-clone hash-key-info"
        />
      </OverlayTrigger>
    </>
  );
};

export default translate(HashKeyInfo);
